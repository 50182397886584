import React, { useContext, useState } from 'react';
import { UserContext } from './UserContext';
import Grid from '@material-ui/core/Grid';
import { QueryPreservingLink } from './QueryPreservingLink';
type User = {
  email: string,
  password: string
}


const Footer = () => {
  const { dispatch } = useContext(UserContext);
  const [showAdditional, setShowAdditional] = useState(false);

  const handleAdditionalFaq = () => {
    setShowAdditional(!showAdditional)
  }

  return (
    <div className="col-sm-12">
      <div className="row">
        <div className="col-sm-12 footer">
          <div className="section5">
            <Grid
              container
              style={{ padding: '0 48px', maxWidth: '1264px', marginLeft: 'auto', marginRight: 'auto' }}
            >
              <Grid item xs={6} sm={4}>
                <QueryPreservingLink href="/faq" as="/faq"><a style={{ fontWeight: 400, color: '#FFFFFF', fontSize: "16px" }}><div style={{ padding: '4px 0' }}>Frequently Asked Questions</div></a>
                </QueryPreservingLink>
                <a href="https://pppays.com" rel="noopener noreferrer" target="_blank" style={{ fontWeight: 400, color: '#FFFFFF', fontSize: "16px" }}><div style={{ padding: '4px 0' }}>Privacy</div></a>
                <a href="https://pppays.com" rel="noopener noreferrer" target="_blank" style={{ fontWeight: 400, color: '#FFFFFF', fontSize: "16px" }}><div style={{ padding: '4px 0' }}>Terms of Service</div></a>
              </Grid>
              <Grid item xs={6} sm={8}>
                <div style={{padding: '16px 0'}}>
                <a href="https://heap.io/?utm_source=badge" rel="nofollow"><img style={{width:"108px",height:"41px"}} src="//heapanalytics.com/img/badge.png" alt="Heap | Mobile and Web Analytics" /></a>
                </div>
              </Grid>
            </Grid>
            <div className='disclaimer'>
            Any loan made under the Program must also be submitted to and approved by the SBA. Program funds are limited. PPPays does not guarantee that applications will be processed and submitted before Program funding is no longer available. There is no cost to you to apply for a Program loan. There is no limit to the number of lenders you can apply with, but you can only receive one PPP loan.
            </div>
          </div>
        </div>
      </div>

      <style jsx>
        {`
        .disclaimer {
          font-size: 12px;
          padding: 24px;
          color: gray;
        }
                @media only screen and (max-width: 960px) {
                  .get_setup_footer {
                    font-size: 24px !important;
                  }
                  .section4  .get_setup_footer{
                    padding-bottom: 24px !important;
                  }
                  .footer-header{
                    margin-top: 16px !important;
                  }
                }

                button:hover,
                button:active {
                  transform: translate3d(0px, -1px, 0px);
                  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
                }

                h2 {
                  color: #333;
                  text-align: center;
                }

                 button {
                  display: block !important;
                  margin-bottom: 0.5rem !important;
                  color: blue !important;
                  border-radius: 5px !important;
                  border: none !important;
                  cursor: pointer !important;
                  transition: all 0.2s ease 0s !important;
                  padding: 10px 25px !important;
                  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12) !important;
                }

                button:hover,
                button:active {
                  transform: translate3d(0px, -1px, 0px) !important;
                  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12) !important;
                }



          .faq :global(a.button),
          .faq :global(a.button:visited),
          .faq :global(a.button:active) {
            display: inline-block;
            color: white;
            background: #1382E9;
            border-radius: 5px;
            padding: 8px 16px;
            font-size: 20px;
          }


          .float-right {
            background-color: white;
            position: absolute;
            right: 50%;
            margin-right: -671px;
            width: 464px;
            height: 644px;
            top: 16px;
            border: 1px solid #4B6075;
            box-sizing: border-box;
            border-radius: 5px;
            box-sizing: border-box;
            padding: 0 20px;
          }
          .float-right h2 {
            font-size: 34px;
            font-weight: normal;
            margin-bottom: 0;
          }
          .float-right h3 {
            font-size: 16px;
            font-weight: normal;
            margin-top: 5px;
            margin-bottom: 10px;
            text-align: center;
            color: #4B6075;
          }
          .inner-column {
            position: relative;
            width: 1200px;
            margin: 0 auto;
          }
          p {
            text-align: center;
            color: #888;
          }
          .hero-tag {
            color: white;
            position: absolute;
            bottom: 52px;
            width: 820px;
            margin-left: -600px;
            left: 50%;
            font-style: normal;
            font-weight: bold;
            font-size: 48px;
            line-height: 142%;
          }
          .hero-img-wrapper {
            position: relative;
            height: 676px;
            overflow: hidden;
            width: 100%;
          }
          .hero-gradient {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: none;
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.2) 51%, rgba(0,0,0,0.45) 100%);
          }
          .hero-img {
            width: 100%;
            min-width: 1824px;
          }
          .section1 {
            background: white;
            padding: 80px 0;
          }
          .callouts {
            font-color: color: #000;
            font-size: 48px;
            text-align: left;
            padding: 0 80px 20px 80px;
          }
          .section1 .table {
            margin-top: 32px;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
          }
          .section1 .table > div {
            text-align: center;
            width: 300px;
            font-size: 20px;
          }
          .section1 .table .title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          .section1 .table .icon {
            display: inline-block;
            height: 80px;
            width: 80px;
            margin-bottom: 24px;
          }
          .section2 {
            width: 1200px;
            background: white;
            display: flex;
            flex-direction: row;
            margin-top: 140px;
            margin: 140px auto 160px auto;
          }
          .section2 .left {
            width: 544px;
            font-size: 24px;
            padding: 80px 40px;
          }
          .section2 .quote {
            margin-bottom: 40px;
          }
          .section4 :global(a.button),
          .section4 :global(a.button:visited),
          .section4 :global(a.button:active) {
            display: inline-block;
            border-radius: 5px;
            padding: 8px 16px;
            font-size: 20px;
          }



                     .section4  .get_setup_footer {
                       position: static;
                       left: 0%;
                       right: 0%;
                       top: 0%;
                       bottom: 0%;

                       font-family: Inter;
                       font-style: normal;
                       font-weight: bold;
                       font-size: 32px;
                       /* or 60px */
                       text-align: left;

                       /* White */
                       color: #000;

                       /* Inside Auto Layout */
                       flex: none;
                       order: 0;
                       align-self: center;
                       margin: 0px 0px;
                    }

          .section2 .quoteText {
            margin-bottom: 50px;
          }
          .section3 {
            text-align: center;
            padding-bottom: 140px;
            display: none;
          }
          .section3 .pricing-small {
            color: #2CA01C;
            font-size: 34px;
            margin-top: 20px;
          }
          .section3 .pricing-large {
            display: flex;
            margin-top: 20px;
            align-items: center;
            justify-content: center;
          }
          .section3 .price {
            color: #2CA01C;
            font-size: 120px;
            font-weight: bold;
          }
          .section3 .strike {
            font-size: 96px;
            text-decoration: line-through;
            padding-left: 60px;
          }
          .section3 .unit {
            font-size: 48px;
            padding-left: 18px;
          }
          .section3 .disclaimer {
            font-size: 24px;
            margin-bottom: 25px;
          }
          .section4 {
            background: #F3F5F6;
            padding: 48px 16px;
            color: black;
            text-align: center;
            width:100%;
          }
          .section5 {
            padding: 16px 16px;
            color: black;
          }
          .section4 .callouts {
            padding: 0;
            padding-bottom: 0px;
          }
          .section4 :global(a.button),
          .section4 :global(a.button:visited),
          .section4 :global(a.button:active) {
            background: white;
            color: #116DC1;
          }
          .footer {
            padding: 16px 0;
            background: #1F2433;
          }
          .footer .inner {
            display: flex;
            margin: 0 auto;
          }
          .footer :global(a),
          .footer :global(a:visited),
          .footer :global(a:active) {
            color: white;
          }
          ul {
            list-style-type: none;
          }
          li :global(a) {
            font-size: 16px
          }
          li {
            padding: 12px;
          }
        `}
      </style>


    </div>


  );
};


export default Footer;
